<template>
    <section>
        <modal ref="modalDenegarPostulacion" titulo="Denegar Postulación" icon="close" @guardar="cambiar_estado">
            <p class="text-general text-center px-5">
                Escribe el mensaje que desea enviarle al {{ $config.vendedor + '.' }}
            </p>
            <div class="row mx-0 px-5">
                <el-input
                v-model="model.justificacion"
                type="textarea"
                :rows="4"
                maxlength="1000"
                show-word-limit
                placeholder="Justificacion"
                />
            </div>
        </modal>
    </section>
</template>
<script>
import Leecheros from "~/services/tienda/tienda"
export default {
    data(){
        return{
            model:{
                id:null,
                justificacion:''
            }
        }
    },
    methods: {
        toggle(id){
            this.model.id = id
            this.model.justificacion = ''
            this.$refs.modalDenegarPostulacion.toggle();
        },
        async cambiar_estado(){
            try {
                if(this.model.justificacion.trim() === ''){
                    this.notificacion('Advertencia','Por favor digite una justificación','warning')
                    return
                }
                this.model.tipo = 3
                const {data} = await Leecheros.cambio_estado(this.model)
                this.$emit('update')
                this.notificacion('Mensaje','Vendedor rechazada correctamente','success')
                this.$refs.modalDenegarPostulacion.toggle();
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
